/**
 * @generated SignedSource<<bcd9edc9514386e16bd98e6d42cb95b9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type UserAuthorizationLevel = "ADMIN" | "VISITOR" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type EventItemNew_query$data = {
  readonly viewer: {
    readonly authorizationLevel: UserAuthorizationLevel;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"EventAttendButtonNew_query">;
  readonly " $fragmentType": "EventItemNew_query";
};
export type EventItemNew_query$key = {
  readonly " $data"?: EventItemNew_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"EventItemNew_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EventItemNew_query",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EventAttendButtonNew_query"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "viewer",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "authorizationLevel",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "78ba9f9d474dc60d3469b55554b58c19";

export default node;
