/**
 * @generated SignedSource<<bc6f3d002a5a9048f2eb9b9f13864b7a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EventItemPresentations_event$data = {
  readonly eventItemPresentations: {
    readonly edges: ReadonlyArray<{
      readonly cursor: String;
      readonly node: {
        readonly presenter: {
          readonly firstName: string;
          readonly lastName: string;
          readonly " $fragmentSpreads": FragmentRefs<"UserPrimaryPhotoNew_user">;
        } | null | undefined;
        readonly presenterFirstName: string | null | undefined;
        readonly presenterLastName: string | null | undefined;
        readonly rowId: String;
        readonly title: string;
      };
    }>;
  };
  readonly " $fragmentType": "EventItemPresentations_event";
};
export type EventItemPresentations_event$key = {
  readonly " $data"?: EventItemPresentations_event$data;
  readonly " $fragmentSpreads": FragmentRefs<"EventItemPresentations_event">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EventItemPresentations_event",
  "selections": [
    {
      "alias": "eventItemPresentations",
      "args": [
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": [
            "CREATED_AT_ASC",
            "ID_ASC"
          ]
        }
      ],
      "concreteType": "PresentationsConnection",
      "kind": "LinkedField",
      "name": "presentations",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PresentationsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Presentation",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "title",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "rowId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "presenterFirstName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "presenterLastName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "User",
                  "kind": "LinkedField",
                  "name": "presenter",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "firstName",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "lastName",
                      "storageKey": null
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "UserPrimaryPhotoNew_user"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "presentations(orderBy:[\"CREATED_AT_ASC\",\"ID_ASC\"])"
    }
  ],
  "type": "Event",
  "abstractKey": null
};

(node as any).hash = "bf5eb5e5d17cc7c64e32a8af0c2b4569";

export default node;
