/**
 * @generated SignedSource<<932bd2155b16bbadbde3b94945186b47>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type EventVisibility = "LISTED" | "UNLISTED" | "%future added value";
export type GuildMembershipLevel = "EDITOR" | "MEMBER" | "ORGANIZER" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type EventItemNew_event$data = {
  readonly attendeesByRelevance: {
    readonly totalCount: number;
    readonly " $fragmentSpreads": FragmentRefs<"EventAttendeesOverlap_eventAttendees">;
  };
  readonly attendingCount: {
    readonly totalCount: number;
  };
  readonly coverPhoto: {
    readonly rowId: String;
  } | null | undefined;
  readonly eventOwner: {
    readonly __typename: "Guild";
    readonly myMembership: {
      readonly membershipLevel: GuildMembershipLevel;
    } | null | undefined;
    readonly name: string;
    readonly primaryPhoto: {
      readonly rowId: String;
    } | null | undefined;
    readonly slugId: string;
    readonly " $fragmentSpreads": FragmentRefs<"GuildPrimaryPhotoNew_guild">;
  } | {
    readonly __typename: "User";
    readonly firstName: string;
    readonly isMe: boolean | null | undefined;
    readonly lastName: string;
    readonly slugId: string;
    readonly " $fragmentSpreads": FragmentRefs<"UserPrimaryPhotoNew_user">;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null | undefined;
  readonly eventTicketingTiers: {
    readonly totalCount: number;
  };
  readonly hasExternalUrl: boolean | null | undefined;
  readonly hasVenue: boolean | null | undefined;
  readonly name: string;
  readonly prettyUrl: string;
  readonly startAt: String;
  readonly visibility: EventVisibility;
  readonly waitingCount: {
    readonly totalCount: number;
  };
  readonly " $fragmentSpreads": FragmentRefs<"EventAttendButtonNew_event" | "EventItemEventCardPhoto_event" | "EventItemPresentations_event">;
  readonly " $fragmentType": "EventItemNew_event";
};
export type EventItemNew_event$key = {
  readonly " $data"?: EventItemNew_event$data;
  readonly " $fragmentSpreads": FragmentRefs<"EventItemNew_event">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "rowId",
    "storageKey": null
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slugId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v4 = [
  (v3/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EventItemNew_event",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "prettyUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasExternalUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasVenue",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "visibility",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Image",
      "kind": "LinkedField",
      "name": "coverPhoto",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": "eventOwner",
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "owner",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v0/*: any*/),
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Image",
              "kind": "LinkedField",
              "name": "primaryPhoto",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "GuildMembership",
              "kind": "LinkedField",
              "name": "myMembership",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "membershipLevel",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "GuildPrimaryPhotoNew_guild"
            }
          ],
          "type": "Guild",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "firstName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lastName",
              "storageKey": null
            },
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isMe",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "UserPrimaryPhotoNew_user"
            }
          ],
          "type": "User",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "attendeesByRelevance",
      "args": [
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "status": {
              "in": [
                "ATTENDING_IN_PERSON",
                "ATTENDING_VIRTUAL",
                "WAITING_IN_PERSON",
                "WAITING_VIRTUAL"
              ]
            }
          }
        },
        {
          "kind": "Literal",
          "name": "first",
          "value": 8
        }
      ],
      "concreteType": "EventAttendeesConnection",
      "kind": "LinkedField",
      "name": "eventAttendeesByRelevance",
      "plural": false,
      "selections": [
        (v3/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "EventAttendeesOverlap_eventAttendees"
        }
      ],
      "storageKey": "eventAttendeesByRelevance(filter:{\"status\":{\"in\":[\"ATTENDING_IN_PERSON\",\"ATTENDING_VIRTUAL\",\"WAITING_IN_PERSON\",\"WAITING_VIRTUAL\"]}},first:8)"
    },
    {
      "alias": "attendingCount",
      "args": [
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "status": {
              "in": [
                "ATTENDING_IN_PERSON",
                "ATTENDING_VIRTUAL"
              ]
            }
          }
        }
      ],
      "concreteType": "EventAttendeesConnection",
      "kind": "LinkedField",
      "name": "eventAttendees",
      "plural": false,
      "selections": (v4/*: any*/),
      "storageKey": "eventAttendees(filter:{\"status\":{\"in\":[\"ATTENDING_IN_PERSON\",\"ATTENDING_VIRTUAL\"]}})"
    },
    {
      "alias": "waitingCount",
      "args": [
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "status": {
              "in": [
                "WAITING_IN_PERSON",
                "WAITING_VIRTUAL"
              ]
            }
          }
        }
      ],
      "concreteType": "EventAttendeesConnection",
      "kind": "LinkedField",
      "name": "eventAttendees",
      "plural": false,
      "selections": (v4/*: any*/),
      "storageKey": "eventAttendees(filter:{\"status\":{\"in\":[\"WAITING_IN_PERSON\",\"WAITING_VIRTUAL\"]}})"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EventTicketingTiersConnection",
      "kind": "LinkedField",
      "name": "eventTicketingTiers",
      "plural": false,
      "selections": (v4/*: any*/),
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EventAttendButtonNew_event"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EventItemEventCardPhoto_event"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EventItemPresentations_event"
    }
  ],
  "type": "Event",
  "abstractKey": null
};
})();

(node as any).hash = "de4ee35065b4a3a33baabc082b7648c9";

export default node;
